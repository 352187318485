<template>
    <div class="container mt-5">
      <h1 class="text-center mb-4">Lista de Roles</h1>
      <div class="card p-4 shadow">
        <ul class="list-group">
          <li 
            v-for="role in roles" 
            :key="role.id" 
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <div>
              <strong>{{ role.name }}</strong> (ID: {{ role.id }})
            </div>
            <div>
              <router-link :to="`/role/${role.id}`" class="btn btn-primary btn-sm">Editar</router-link>
            </div>
          </li>
        </ul>
        <p v-if="roles.length === 0" class="text-center mt-3">Nenhuma role cadastrada.</p>
      </div>
      <button class="btn btn-success mt-4 w-100">Adicionar nova Role</button>
    </div>
  </template>
  
  <script>
  import api from '@/globais/axios';
  
  export default {
    name: 'ListaRoles',
    data() {
      return {
        roles: [],
      };
    },
    async mounted() {
      try {
        const response = await api.get('/roles');
        this.roles = response.data;
      } catch (error) {
        console.error('Erro ao buscar roles:', error);
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>
  
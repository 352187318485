<template>
  <div>
    <div v-if="authStore.isAuthenticated()" class="d-flex">
      <Navbar v-if="authStore.navbar"/>
      <main class="flex-grow-1 p-4">
        <router-view />
        <MensagemGlobal />
      </main>
    </div>

    <div v-else>
      
        <router-view />
        <MensagemGlobal />
      
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/authStore';
import Navbar from '@/components/Navbar.vue';
import MensagemGlobal from '@/components/Mensagens/MensagemGlobal.vue';

const authStore = useAuthStore();
</script>

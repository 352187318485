<template>
  <div>
    <h4>Acessos</h4>

    <div class="mb-3">
  <label>Funções:</label>
  <div v-for="item in funcoesdisponiveis" :key="item.id">
    <input
      type="radio"
      name="funcao"
      :value="item.id"
      :id="'funcao-' + item.id"
      :checked="funcao === item.id"
      @change="toggleRole(item.id)"
    />
    <label :for="'funcao-' + item.id">{{ item.nome }}</label>
  </div>
</div>


    <div class="mb-3">
      <label>Telas:</label>
      <div v-for="tela in telasDisponiveis" :key="tela.id">
        <input
          type="checkbox"
          :value="tela.id"
          :id="'tela-' + tela.id"
          :checked="telas.includes(tela.id)"
          @change="toggleTela(tela.id)"
        />
        <label :for="'tela-' + tela.id">{{ tela.nomeTela }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    funcao: { // v-model:funcao
      type: Array,
      default: () => []
    },
    telas: { // v-model:telas
      type: Array,
      default: () => []
    },
    funcoesdisponiveis: { // opções disponíveis para funções
      type: Array,
      default: () => []
    },
    telasDisponiveis: { // opções disponíveis para telas
      type: Array,
      default: () => []
    }
  },
  emits: ["update:funcao", "update:telas"],
  methods: {
    toggleRole(id) {
  this.$emit("update:funcao", id);
},
    toggleTela(id) {
      const newTelas = [...this.telas];
      if (newTelas.includes(id)) {
        newTelas.splice(newTelas.indexOf(id), 1);
      } else {
        newTelas.push(id);
      }
      this.$emit("update:telas", newTelas);
    }
  }
};
</script>

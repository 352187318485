import { defineStore } from 'pinia';
import api from '@/globais/axios';
import router from '@/router';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null,
    nome: '',
    permissoes: {},
    navbar: false, // agora já é boolean
  }),
  actions: {
    async login(email, senha) {
      console.log("here")
      const response = await axios.post('/api/auth/login', { email, senha });
      console.log(response);
      this.token = response.data.token;
      this.nome = response.data.nome;
      localStorage.setItem("authToken", this.token);
      
      
      const res = await api.get('usuarios/disponiveis/me/permissoes');
      console.log(res);
      

      this.permissoes = res.data;
      this.navbar = res.data.navbar;

      // Persistência
      localStorage.setItem("permissoes", JSON.stringify(this.permissoes));
      localStorage.setItem("nome", this.nome);
      localStorage.setItem("navbar", JSON.stringify(this.navbar)); // salva como boolean
    },

    logout() {
      this.token = null;
      this.nome = '';
      this.permissoes = {};
      this.navbar = false;
      localStorage.removeItem("authToken");
      localStorage.removeItem("nome");
      localStorage.removeItem("permissoes");
      localStorage.removeItem("navbar");
    },

    isAuthenticated(){
      return !!this.token;
    },

    /** Carregar do localStorage ao iniciar */
    loadFromLocalStorage() {
      this.token = localStorage.getItem("authToken") || null;
      this.nome = localStorage.getItem("nome") || '';
      const permissoes = localStorage.getItem("permissoes");
      this.permissoes = permissoes ? JSON.parse(permissoes) : {};
  
      const navbarRaw = localStorage.getItem("navbar");
      if (navbarRaw === "undefined" || navbarRaw === null || navbarRaw === "") {
          this.navbar = false;
      } else {
          try {
              this.navbar = JSON.parse(navbarRaw);
          } catch (e) {
              this.navbar = false;
          }
      }
  }
  
  
  }
});

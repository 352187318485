<template>
  <!-- Container principal que ocupa 100% da altura da tela -->
  <div
    class="bg-container d-flex align-items-center justify-content-center"
    style="background-image: url('/PortalDoFranqueado/img/icons/teste1.jpg')"
  >
    <div class="card p-4 card-transparent text-white" style="max-width: 500px; width: 100%;">
      <div class="mb-4 text-center">
        <h3>Login Portal Txc</h3>
        <p>Bem vindo</p>
      </div>

      <form @submit.prevent="fazerLogin">
        <div class="form-group mb-3">
          <label>Email</label>
          <input v-model="email" type="text" class="form-control" />
        </div>

        <div class="form-group mb-3">
          <label>Senha</label>
          <input v-model="senha" type="password" class="form-control" required />
        </div>

        <div class="d-flex mb-4 align-items-center justify-content-between">
          <div>
            <input type="checkbox" checked="checked" />
            <span class="ms-1">Lembrar-me</span>
          </div>
          <a href="#" class="forgot-pass">Esqueci minha senha</a>
        </div>

        <button type="submit" class="btn btn-primary w-100 button-color">
          Entrar
        </button>
      </form>
    </div>

    <MensagemGlobal />
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useMensagemStore } from "@/stores/mensagemStore";
import router from "@/router";
import MensagemGlobal from "./Mensagens/MensagemGlobal.vue";

export default {
  name: "Login",
  setup() {
    const email = ref("");
    const senha = ref("");
    const authStore = useAuthStore();
    const mensagem = useMensagemStore();
    const isAutenticado = ref({});

    const fazerLogin = async () => {
      try {
        await authStore.login(email.value, senha.value);
        router.push({ name: 'home' });
      } catch (error) {
        mensagem.mostrar('Erro ao realizar login. Verifique seus dados.', 'erro');
      }finally{
        if(isAutenticado){
          router.push ({ name: 'home'})
        }else{
          mensagem.mostrar('Erro na autenticação, contate o suporte!', 'error')
        }
      }
      
    };
      const checkAutenticado = () => {
      const token = authStore.token;
        if(token){
          isAutenticado.value = true;
        }else{
          isAutenticado.value = false;
        }

    }
      onMounted(() => {
        checkAutenticado();
      })

    return { email, senha, fazerLogin, isAutenticado, mensagem };
  },
};
</script>



<style scoped>
/* Remove margem/padding do body para ocupar a tela inteira */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Classe para ocupar a tela inteira e exibir a imagem de fundo */
.bg-container {
  width: 100%;
  height: 100vh; /* Toma a altura total da viewport */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.forgot-pass {
  font-size: 0.9rem;
  text-decoration: none;
  color: rgba(87,45,30,255);
}

.card-transparent {
  background-color: black; /* Pode ajustar a opacidade de 0.1 a 1 */
  border: none;
}

.button-color {
  background-color: rgba(87,45,30,255);
  border: none;
}

input[type="checkbox"] {
  accent-color: rgba(87,45,30,255);
}
@media (max-width: 767px) {
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-height: 30vh; /* Ocupa aproximadamente 30% da altura da tela */
    overflow: auto;
    text-align: center;
  }
}

/* Estilo para desktop: notificação fixa no canto superior direito */
@media (min-width: 768px) {
  .popup-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;
  }
  .popup-content {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    min-width: 200px;
    max-width: 300px;
    text-align: center;
  }
}

/* Transição para o popup */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

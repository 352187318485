<template>
  <transition name="fade">
    <div v-if="mensagem.visivel" class="popup-container">
      <div class="popup-content">
        {{ mensagem.texto }}
      </div>
    </div>
  </transition>
</template>

<script>
import { useMensagemStore } from '@/stores/mensagemStore';

export default {
  setup() {
    const mensagem = useMensagemStore();
    return { mensagem };
  },
};
</script>

<style scoped>
/* Estilo para dispositivos móveis: overlay centralizado com fundo semitransparente */
@media (max-width: 767px) {
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-height: 30vh; /* Ocupa aproximadamente 30% da altura da tela */
    overflow: auto;
    text-align: center;
  }
}

/* Estilo para desktop: notificação fixa no canto superior direito */
@media (min-width: 768px) {
  .popup-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;
  }
  .popup-content {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    min-width: 200px;
    max-width: 300px;
    text-align: center;
  }
}

/* Transição para o popup */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

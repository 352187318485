import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';

// Importa Bootstrap CSS e JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import MensagemGlobal from './components/Mensagens/MensagemGlobal.vue';

const app = createApp(App);

// Define uma propriedade global
app.config.globalProperties.$ipBackend = 'http://172.16.0.193:8081';

// Instância do Pinia
const pinia = createPinia();

//Componentes Globais
app.component('MensagemGloBalPopup', MensagemGlobal);

// Usa separadamente o router e o pinia
app.use(pinia);
app.use(router);

app.mount('#app');

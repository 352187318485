<template>
  <div class="container mt-5">
    <h2 class="mb-4">Cadastro de Usuário</h2>

    <!-- Navegação das abas -->
    <ul class="nav nav-tabs mb-4">
      <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
        <a 
          class="nav-link" 
          :class="{ active: activeTab === index }" 
          href="#"
          @click.prevent="activeTab = index"
        >
          {{ tab }}
        </a>
      </li>
    </ul>

    <!-- Conteúdo das Abas -->
    <div v-if="activeTab === 0">
      <TabUsuario v-model="usuario" />
    </div>

    <div v-if="activeTab === 1">
          <TabAcessos
      v-model:funcao="usuario.funcao"
      v-model:telas="usuario.telas"
      :telasDisponiveis="permissionsDisponiveis.telas"
      :funcoesdisponiveis="permissionsDisponiveis.funcao"
      />

    </div>

    <div v-if="activeTab === 2">
      <TabConfiguracoes />
    </div>

    <div v-if="activeTab === 3">
      <TabLojas 
        v-model="usuario.lojas"
        :lojasDisponiveis="permissionsDisponiveis.lojas"
      />
    </div>

    <!-- Botão de Salvar -->
    <button class="btn btn-primary mt-4 w-100" @click="salvarUsuario">Salvar Usuário</button>

    <p v-if="mensagem" class="mt-3 alert" :class="mensagem.includes('sucesso') ? 'alert-success' : 'alert-danger'">
      {{ mensagem }}
    </p>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import api from "@/globais/axios";

// Importação das abas
import TabUsuario from './TabsCadastro/TabUsuario.vue';
import TabAcessos from './TabsCadastro/TabAcessos.vue';
import TabConfiguracoes from "./TabsCadastro/TabConfiguracoes.vue";
import TabLojas from "./TabsCadastro/TabLojas.vue";

export default {
  components: {
    TabUsuario,
    TabAcessos,
    TabConfiguracoes,
    TabLojas
  },
  setup() {
    const route = useRoute();
    const activeTab = ref(0);
    const tabs = ["Usuário", "Acessos", "Configurações", "Lojas"];

    const usuario = ref({
      nome: "",
      sobrenome: "",
      email: "",
      telefone: "",
      senha: "",
      funcao: "",
      lojas: "",
      telas: [],
      navbar: false
    });

    const permissionsDisponiveis = ref({
      funcao: "",
      telas: [],
      lojas: ""
    });

    const mensagem = ref("");

    onMounted(async () => {
      try {
        const res = await api.get("/usuarios/disponiveis");
        permissionsDisponiveis.value = res.data;
        

        if (route.params.id) {
          const userRes = await api.get(`/usuarios/${route.params.id}`);
          usuario.value = userRes.data;
          console.log(usuario.value);
        }
      } catch (err) {
        console.error("Erro ao carregar dados", err);
      }
    });

    const salvarUsuario = async () => {
      try {
        usuario.value.telas = [1];
        await api.post("/auth/register", usuario.value);
        mensagem.value = "Usuário cadastrado com sucesso!";
      } catch (err) {
        mensagem.value = "Erro ao cadastrar usuário.";
        console.error(err);
      }
    };

    return {
      activeTab,
      tabs,
      usuario,
      permissionsDisponiveis,
      mensagem,
      salvarUsuario
    };
  }
};
</script>

<style scoped>
.container {
  max-width: 700px;
}

.nav-tabs .nav-link {
  cursor: pointer;
}
</style>

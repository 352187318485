<template>


<div class="teste" v-if="dados">
    <div class="card">
        <div class="card-header">

        </div>

        <div class="card-body">
            <table class="table">
                <thead>

                    <tr>
                        <th scrope="col">Id</th>
                        
                        <th scrope="col">Nome</th>
                        
                        <th scrope="col">Link</th>
                        
                        <th scrope="col">Web Url</th>
                        
                    </tr>
                    
                </thead>
                <tbody class="table-body" v-for="dado in dados">
                    <tr>
                        <th scope="row">
                            {{ dado.idBanco }}
                        </th>
                        <td>
                            {{ dado.name }} 
                        </td>
                        <td>
                            <a> {{ dado.webUrl }}  </a>  
                        </td>
                        <td>
                            <a> {{ dado.embedUrl }} </a>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
</div>
<div class="testecarregando" v-else>
    <h1>Carregando</h1>
</div>


</template>

<script>

import axios from 'axios';

export default {
    data(){
        return{
            dados: null
        }
    },
    created(){
        axios.get('http://172.16.0.72:8081/bi/relatorios')
        .then(response => {
            this.dados = response.data;
        })
        .catch(error =>{
            console.log(error)
        })
    }
}



</script>
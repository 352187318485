import axios from "axios";
import { useAuthStore } from "@/stores/authStore";

const token = localStorage.getItem("authToken");
const ipBackend = '/api';

const api = axios.create({
    baseURL: ipBackend,
    headers: {
        'Content-Type':'application/json'
    }
})


api.interceptors.request.use(config => {
    const fullUrl = `${config.baseURL}${config.url}`;
    
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    } else {
        delete config.headers.Authorization;
    }
    return config;
}, error => {
    return Promise.reject(error);
});



export default api;
<template>
  <div class="container mt-5">
    <h1 class="text-center mb-4">Lista de Patrimônios</h1>

    <!-- Caixa de pesquisa -->
    <div class="mb-3">
      <input 
        type="text" 
        class="form-control" 
        placeholder="Buscar por nome..." 
        v-model="searchQuery"
      />
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <!-- Use 'paginatedPatrimonios' para exibir apenas os itens filtrados da página atual -->
        <tr v-for="item in paginatedPatrimonios" :key="item.id">
          <td>{{ item.nome }}</td>
          <td>
            <button class="btn btn-warning btn-sm me-2" @click="editarPatrimonio(item.id)">
              Editar
            </button>
            <button class="btn btn-danger btn-sm" @click="excluirPatrimonio(item.id)">
              Excluir
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Paginação -->
    <div class="d-flex justify-content-center" v-if="totalPages > 1">
      <nav>
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button 
              class="page-link" 
              @click="changePage(currentPage - 1)" 
              :disabled="currentPage === 1"
            >
              Anterior
            </button>
          </li>
          
          <li 
            v-for="page in totalPages" 
            :key="page" 
            class="page-item" 
            :class="{ active: currentPage === page }"
          >
            <button class="page-link" @click="changePage(page)">
              {{ page }}
            </button>
          </li>

          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button 
              class="page-link" 
              @click="changePage(currentPage + 1)" 
              :disabled="currentPage === totalPages"
            >
              Próximo
            </button>
          </li>
        </ul>
      </nav>
    </div>

    <!-- Mensagem de sucesso ou erro -->
    <p
      v-if="mensagem"
      class="mt-3 alert"
      :class="mensagem.includes('sucesso') ? 'alert-success' : 'alert-danger'"
    >
      {{ mensagem }}
    </p>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/globais/axios";

export default {
  name: "ListaPatrimonios",
  data() {
    return {
      patrimonios: [],
      mensagem: "",
      token: localStorage.getItem("authToken"),
      
      // Propriedades para paginação
      currentPage: 1,
      itemsPerPage: 12, // Quantos itens por página
      
      // Query de pesquisa
      searchQuery: "",
    };
  },
  created() {
    api
      .get("/patrimonio")
      .then((response) => {
        this.patrimonios = response.data;
      })
      .catch((error) => {
        console.error("Erro ao carregar patrimônios:", error);
        this.mensagem = "Erro ao carregar patrimônios.";
      });
  },
  computed: {
    // Filtra a lista de patrimonios de acordo com a pesquisa
    filteredPatrimonios() {
      if (!this.searchQuery.trim()) {
        return this.patrimonios;
      }
      const queryLower = this.searchQuery.toLowerCase();
      return this.patrimonios.filter((item) =>
        item.nome.toLowerCase().includes(queryLower)
      );
    },
    // Quantas páginas ao todo, com base no resultado filtrado
    totalPages() {
      return Math.ceil(this.filteredPatrimonios.length / this.itemsPerPage);
    },
    // Retorna apenas os itens da página atual, com base no resultado filtrado
    paginatedPatrimonios() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredPatrimonios.slice(startIndex, endIndex);
    },
  },
  methods: {
    // Muda para a página informada, caso seja válida
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    editarPatrimonio(id) {
      this.$router.push({ name: "patrimonios", params: { id } });
    },
    excluirPatrimonio(id) {
      axios
        .delete(`${this.$ipBackend}/patrimonio/${id}`)
        .then(() => {
          this.mensagem = "Patrimônio excluído com sucesso!";
          // Remove da lista o item excluído
          this.patrimonios = this.patrimonios.filter(
            (item) => item.id !== id
          );
          // Se remover o último item da página atual e não for a primeira página,
          // pode voltar uma página
          if (
            this.currentPage > 1 &&
            this.paginatedPatrimonios.length === 0
          ) {
            this.changePage(this.currentPage - 1);
          }
        })
        .catch((error) => {
          console.error("Erro ao excluir patrimônio:", error);
          this.mensagem = "Erro ao excluir patrimônio.";
        });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
}

.table {
  margin-top: 20px;
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';

import Login from '@/components/Login.vue';
import Dashboard from '@/components/Dashboard.vue';
import UserCadastro from '@/components/UserCadastro.vue';
import PowerbiReports from '@/components/PowerbiReports.vue';
import Patrimonios from '@/components/Patrimonios.vue';
import ListaPatrimonio from '@/components/ListaPatrimonio.vue';
import Termo from '@/components/Termo.vue';
import Lojas from '@/components/Lojas.vue';
import CadastroLojas from '@/components/CadastroLojas.vue';
import ListRoles from '@/components/ListRoles.vue';
import Role from '@/components/Role.vue';
import CadastroDeUsuario from '@/components/CadastroDeUsuario.vue';
import Usuarios from '@/components/Usuarios/Usuarios.vue';
import Home from '@/components/Home/Home.vue';
import DuvidasFrequentes from '@/components/Duvidas/DuvidasFrequentes.vue';
import Mandamentos from '@/components/Duvidas/Mandamentos.vue';
import ContatosSAF from '@/components/Duvidas/ContatosSAF.vue';

var auth = true;

const routes = [
  { path: '/login', name: 'login', component: Login, meta: { title: "Login - Portal do Franqueado TXC" }},
  { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { requiresAuth: auth, title: "Dashboard -  TXC" }},
  { path: '/userCadastro', name: 'userCadastro', component: CadastroDeUsuario, meta: { requiresAuth: auth, title: "Cadastro de Usuário -  TXC" }},
  { path: '/powerbiReports', name: 'powerbiReports', component: PowerbiReports, meta: { requiresAuth: auth, title: "Dashboards -  TXC" }},
  { path: '/patrimonios/:id?', name: 'patrimonios', component: Patrimonios, meta: { requiresAuth: auth, title: "Cadastro de Patrimonios -  TXC" }},
  { path: '/ListaPatrimonios', name: 'listaPatrimonios', component: ListaPatrimonio, meta: { requiresAuth: auth, title: "Lista de Patrimonios -  TXC" }},
  { path: '/Termo', name: 'termo', component: Termo, meta: { requiresAuth: auth, title: "Termos -  TXC" }},
  { path: '/Lojas', name: 'lojas', component: Lojas, meta: { requiresAuth: auth, title: "Lista de Lojas -  TXC" }},
  { path: '/Loja/:id?', name: 'cadastroLojas', component: CadastroLojas, meta: { requiresAuth: auth, title: "Cadastro de Loja -  TXC" }},
  { path: '/Roles', name: 'roles', component: ListRoles, meta: { requiresAuth: auth, title: "Roles -  TXC" }},
  { path: '/Role/:id?', name: 'role', component: Role, meta: { requiresAuth: auth, title: "Cadastro de Roles -  TXC" }},
  { path: '/', redirect: { name: 'login' }},
  { path: '/usuario/:id?', name: 'cadastroUsuario', component: CadastroDeUsuario, meta: { requiresAuth: auth, title: "Cadastro de Usuarios - Total" }},
  { path: '/Usuarios', name: 'usuarios', component: Usuarios, meta: { requiresAuth: auth, title: "Lista de Usuarios - Total" }},
  { path: '/home', name: 'home', component: Home, meta: { requiresAuth: auth, title: "Home" }},
  { path: '/duvidasfrequentes', name: 'duvidasfrequentes', component: DuvidasFrequentes, meta: {requiresAuth: auth, title: "Dúvidas Frequentes"}},
  { path: '/mandamentos', name: 'mandamentos', component: Mandamentos, meta: {requiresAuth: auth, title: "Dúvidas Frequentes"}},
  {path: '/contatosaf', name: 'contatosaf', component: ContatosSAF, meta: {requiresAuth: auth, title: "Contatos Saf"}},
  { path: '/:pathMatch(.*)*', redirect: { name: 'login' }}
];

const router = createRouter({
  history: createWebHistory('/portaldofranqueado/'),
  routes
});

// Proteção de rotas e gerenciamento de login/logout
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  // Carrega do localStorage apenas uma vez
  if (!authStore.token) {
    authStore.loadFromLocalStorage();
  }

  document.title = to.meta.title || 'Portal do Franqueado TXC';


  // Proteção das rotas privadas
  if (to.meta.requiresAuth && !authStore.isAuthenticated()) {
    next({ name: 'login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;

import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMensagemStore = defineStore('mensagemStore', () => {
  const texto = ref('');
  const tipo = ref('info'); // info, sucesso, erro, warning
  const visivel = ref(false);

  function mostrar(msg, tipoMsg = 'info') {
    texto.value = msg;
    tipo.value = tipoMsg;
    visivel.value = true;
    setTimeout(() => visivel.value = false, 3000);
  }

  return { texto, tipo, visivel, mostrar };
});

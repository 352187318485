<template>
  <div class="container mt-5">
    <h1 class="text-center mb-4">{{ tituloDaPagina }}</h1>
    <form @submit.prevent="salvarPatrimonio" class="card p-4 shadow">
      <div class="mb-3">
        <label for="nome" class="form-label">Nome</label>
        <input type="text" id="nome" v-model="patrimonio.nome" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="departamento" class="form-label">Departamento</label>
        <select
          id="departamento"
          v-model="patrimonio.departamento"
          class="form-select"
          required
        >
          <option disabled value="">Selecione um departamento</option>
          <option value="Administrativo">Administrativo</option>
          <option value="Atendimento ao cliente">Atendimento ao cliente</option>
          <option value="Cobrança">Cobrança</option>
          <option value="Compras">Compras</option>
          <option value="Controladoria">Controladoria</option>
          <option value="E-commerce">E-commerce</option>
          <option value="Engenharia de Produto">Engenharia de Produto</option>
          <option value="Estilo">Estilo</option>
          <option value="Expansão e Novos Negócios">Expansão e Novos Negócios</option>
          <option value="Expedição">Expedição</option>
          <option value="Faturamento">Faturamento</option>
          <option value="Financeiro">Financeiro</option>
          <option value="Fiscal">Fiscal</option>
          <option value="Franquias">Franquias</option>
          <option value="Juridico">Juridico</option>
          <option value="Logística">Logística</option>
          <option value="Marketing">Marketing</option>
          <option value="PCP">PCP</option>
          <option value="Qualidade">Qualidade</option>
          <option value="Recebimento">Recebimento</option>
          <option value="Relacionamento ao Cliente">Relacionamento ao Cliente</option>
          <option value="Representação">Representação</option>
          <option value="ShowRoom">ShowRoom</option>
          <option value="RH">RH</option>
          <option value="Ti">Ti</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="descricao" class="form-label">Descrição</label>
        <input type="text" id="descricao" v-model="patrimonio.descricao" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="nPatrimonioPc" class="form-label">Número do Patrimônio</label>
        <input type="text" id="nPatrimonioPc" v-model="patrimonio.npatrimonioPc" class="form-control" />
      </div>
      <button type="submit" class="btn btn-primary w-100">
        {{ isEdicao ? "Atualizar" : "Cadastrar" }}
      </button>
    </form>
    <p
      v-if="mensagem"
      class="mt-3 alert"
      :class="mensagem.includes('sucesso') ? 'alert-success' : 'alert-danger'"
    >
      {{ mensagem }}
    </p>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import api from '@/globais/axios';

export default {
  name: "CadastroPatrimonio",
  setup() {
    // Objeto de dados do Patrimônio
    const patrimonio = ref({
      nome: "",
      departamento: "",
      descricao: "",
      npatrimonioPc: ""
    });

    const mensagem = ref("");

    // Acessa a variável global $ipBackend definida em main.js
    const { appContext } = getCurrentInstance();
    const ipBackend = appContext.config.globalProperties.$ipBackend;

    // Para navegar e para ler parâmetros de rota
    const router = useRouter();
    const route = useRoute();

    // Pega o "id" se existir na rota
    const idParam = route.params.id;

    // Verifica se é modo edição (id presente)
    const isEdicao = computed(() => !!idParam);

    // Título da página
    const tituloDaPagina = computed(() => isEdicao.value 
      ? "Edição de Patrimônio"
      : "Cadastro de Patrimônio"
    );

    // Ao montar, se for edição, carrega os dados do backend
    onMounted(() => {
      if (isEdicao.value) {
        carregarPatrimonio(idParam);
      }
    });

    // Função para carregar o patrimônio do backend
    const carregarPatrimonio = async (id) => {
      try {
        const response = await axios.get(`${ipBackend}/patrimonio/${id}`);
        // Preenche o form com os dados recebidos
        patrimonio.value = response.data;

      } catch (error) {
        mensagem.value = "Erro ao carregar patrimônio.";
        console.error(error);
      }
    };

    // Submissão do formulário: se for edição, PUT; caso contrário, POST
    const salvarPatrimonio = async () => {
      try {
        if (isEdicao.value) {
          // Edição

          await axios.put(`${ipBackend}/patrimonio/${idParam}`, patrimonio.value);
          mensagem.value = "Patrimônio atualizado com sucesso!";
        } else {
          // Cadastro
          await axios.post(`${ipBackend}/patrimonio`, patrimonio.value);
          mensagem.value = "Patrimônio cadastrado com sucesso!";
        }
        // Redireciona após 1.5s
        setTimeout(() => router.push("/ListaPatrimonios"), 1000);
      } catch (error) {
        mensagem.value = isEdicao.value
          ? "Erro ao atualizar patrimônio. Tente novamente."
          : "Erro ao cadastrar patrimônio. Tente novamente.";
        console.error("Erro:", error);
      }
    };

    return {
      patrimonio,
      mensagem,
      isEdicao,
      tituloDaPagina,
      salvarPatrimonio
    };
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
}

.card {
  border: none;
  border-radius: 10px;
  padding: 20px;
}

.alert {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}
</style>

<template>
  <div v-if="auth.navbar" class="d-flex">
    <!-- Sidebar -->
    <nav class="navbar navbar-expand-lg bg-body-tertiary vh-100 flex-column align-items-start sidebar">
      <div class="container-fluid flex-column h-100">
        <router-link to="/home" class="navbar-brand mb-4">TXC</router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse w-100 h-100 d-flex flex-column" id="navbarNavDropdown">
          <!-- Itens da Navbar -->
          <ul class="navbar-nav flex-column w-100">
              <li class="nav-item dropdown" :class="{ show: abrirDropdown }">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  @click.prevent="abrirDropdown = !abrirDropdown"
                >
                  Cadastros
                </a>
                <ul class="dropdown-menu" :class="{ show: abrirDropdown }">
                  <li>
                    <router-link to="/home" class="dropdown-item">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/userCadastro" class="dropdown-item">Cadastro de Usuários</router-link>
                  </li>
                  <li>
                    <router-link to="/Loja" class="dropdown-item">Cadastro de Lojas</router-link>
                  </li>
                  <li>
                    <router-link to="/Role" class="dropdown-item">Cadastro de Role</router-link>
                  </li>
                  <li>
                    <router-link to="/powerbiReports" class="dropdown-item">PowerBI Reports</router-link>
                  </li>
                  <li>
                    <router-link to="/patrimonios" class="dropdown-item">Patrimônios</router-link>
                  </li>
                  <li><a class="dropdown-item" href="#">Cadastro de Bi</a></li>
                </ul>
              </li>

            <li class="nav-item">
              <router-link to="/dashboard" class="nav-link active" aria-current="page">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/ListaPatrimonios" class="nav-link active" aria-current="page">Lista de Patrimonios</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/Roles" class="nav-link active" aria-current="page">Roles</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/Termo" class="nav-link active" aria-current="page">Termos</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/powerbiReports" class="nav-link active" aria-current="page">Power Bi Reports</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Gestão de Bis</a>
            </li>
          </ul>

          <!-- Botão de Logout na parte inferior da sidebar -->
          <ul class="navbar-nav flex-column w-100 mt-auto">
            <li class="nav-item">
              <button @click="logout" class="btn btn-outline-dark w-100">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    
  </div>
  <div v-else>

  </div>
</template>

<script>
import { useAuthStore } from "@/stores/authStore";
import { onMounted, ref } from "vue";
import router from "@/router";

export default {
  setup() {
    const auth = useAuthStore();
    const abrirDropdown = ref(false);

    const logout = () => {
      auth.logout();
      router.push('/login');
    };

    onMounted( () => {
      auth.loadFromLocalStorage();
    })
    return {
      auth, // Retorna o store inteiro para manter a reatividade no template
      abrirDropdown,
      logout
    };
  }
};
</script>



<style scoped>
.sidebar {
  width: 250px; /* Largura da sidebar */
  background-color: #f8f9fa; /* Cor de fundo */
  border-right: 1px solid #dee2e6; /* Borda à direita */
}

.navbar-nav {
  width: 100%;
}

.nav-item {
  width: 100%;
}

.nav-link {
  padding: 10px 15px;
  width: 100%;
}

.nav-link:hover {
  background-color: #e9ecef; /* Cor de fundo ao passar o mouse */
}

.dropdown-menu {
  width: 100%;
}

.btn-outline-dark {
  margin-top: 20px; /* Espaçamento acima do botão de logout */
}
</style>
<template>
    <div class="container mt-5">
      <h1 class="text-center mb-4">Cadastrar Nova Role</h1>
      <form @submit.prevent="salvarRole" class="card p-4 shadow">
        <div class="mb-3">
          <label for="nome" class="form-label">Nome da Role</label>
          <input
            type="text"
            id="nome"
            v-model="role.name"
            class="form-control"
            placeholder="Ex: ROLE_FRANQUIA"
            required
          />
        </div>
        <button type="submit" class="btn btn-success w-100">Cadastrar</button>
        <p
          v-if="mensagem"
          class="mt-3 alert"
          :class="mensagem.includes('sucesso') ? 'alert-success' : 'alert-danger'"
        >
          {{ mensagem }}
        </p>
      </form>
    </div>
  </template>
  
  <script>
  import api from '@/globais/axios';
  
  export default {
    name: 'CadastroRole',
    data() {
      return {
        role: {
          name: ''
        },
        mensagem: ''
      };
    },
    methods: {
      async salvarRole() {
              if (!this.role.name.trim()) {
          this.mensagem = 'O campo nome da role é obrigatório';
          return;
        }
        try {
          await api.post('/roles', this.role);
          this.mensagem = 'Role cadastrada com sucesso';
          this.role.name = '';
        } catch (error) {
          this.mensagem = 'Erro ao cadastrar a role';
          console.error('Erro:', error);
        }
      }
    }
  };
  </script>
  
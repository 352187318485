<template>
  <div class="container mt-5">
    <h2>Lista de Usuários</h2>

    <!-- Tabela de usuários -->
    <table class="table table-bordered mt-4" v-if="usuarios.length > 0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nome</th>
          <th>Email</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="usuario in usuarios" :key="usuario.id">
          <td>{{ usuario.nome }}</td>
          <td>{{ usuario.id }}</td>
          <td>{{ usuario.email }}</td>
          <td>
            <button class="btn btn-primary btn-sm" @click="editarUsuario(usuario.nome)">
              Editar
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <p v-else class="text-center mt-4">Nenhum usuário encontrado.</p>
  </div>
</template>

<style scoped>
.container {
  max-width: 900px;
}

.table th,
.table td {
  vertical-align: middle;
}

.text-center {
  text-align: center;
}
</style>

<script>
import api from "@/globais/axios";

export default {
  data() {
    return {
      usuarios: [],
    };
  },
  mounted() {
    this.carregarUsuarios();
  },
  methods: {
    async carregarUsuarios() {
      try {
        const response = await api.get("/usuarios");
        // Aqui tratamos se vier {nome: id} como antes
        this.usuarios = Object.entries(response.data).map(([nome, id, email]) => ({
          nome,
          id,
          email, // Você pode substituir pelo campo correto, caso venha no backend
        }));
      } catch (error) {
        console.error("Erro ao carregar usuários:", error);
      }
    },
    editarUsuario(id) {
      this.$router.push(`/usuario/${id}`);
    },
  },
};
</script>

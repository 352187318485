<template>
  <div>
    <h4>Selecionar Loja</h4>
    <div v-for="loja in lojasDisponiveis" :key="loja.id" class="form-check">
      <input
        type="radio"
        :id="'loja-' + loja.id"
        :value="loja.id"
        :checked="modelValue === loja.id"
        @change="$emit('update:modelValue', loja.id)"
        name="loja-radio"
        class="form-check-input"
      />
      <label :for="'loja-' + loja.id" class="form-check-label">
        {{ loja.nome }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabLojas",
  props: {
    modelValue: {
      type: [String, Number, null],
      default: null
    },
    lojasDisponiveis: {
      type: Array,
      default: () => []
    }
  },
  emits: ["update:modelValue"]
};
</script>

<style scoped>
.form-check {
  margin-bottom: 10px;
}
</style>

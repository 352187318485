<template>
    <div class="container mt-5">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title text-center">🏆 Dashboard</h1>
          <p class="text-center">Login realizado com sucesso!</p>

          <div class="alert alert-success">
            <iframe title="Faturamento Geral TXC NEWS" width="600" height="373.5" src="https://app.powerbi.com/view?r=eyJrIjoiMTViMTc0NzktODhmYy00NmQ3LWE4ZjctNDgyNDdhZGZlMGI2IiwidCI6IjQ1ZDlkZjE0LThkY2UtNDhlMS05MThjLWU3ZmM0OGJmN2JiZiJ9" frameborder="0" allowFullScreen="true"></iframe>
          </div>
  
          <!-- Exibe o e-mail do usuário autenticado -->
          <div v-if="userEmail" class="alert alert-success">
            Bem-vindo, <strong>{{ userEmail }}</strong>! Você está autenticado.

          </div>
  
          <!-- Botão de Logout -->
          <div class="d-grid">
            <button class="btn btn-danger mt-3" @click="logout">Sair</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>




  export default {
    data() {
      return {
        userEmail: ''
      };
    },
    mounted() {
      this.userEmail = localStorage.getItem('userEmail');
    },
    methods: {
      logout() {
        // Remove o token e redireciona para login
        localStorage.removeItem('authToken');
        localStorage.removeItem('userEmail');
        this.$router.push('/login');
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: auto;
  }
  </style>
  
<template>
    <div class="container mt-5">
      <div class="card shadow-lg">
        <div class="card-body">
          <h1 class="card-title text-center mb-4">
            Atendimento Saf
          </h1>
          <hr />
  
          <ul class="list-group list-group-flush">
            <li v-for="(mandamento, index) in mandamentos" :key="index" class="list-group-item">
              <strong>{{ index + 1 }}º:</strong> {{ mandamento }}
            </li>
          </ul>
  
          <div class="text-center mt-4">
            <RouterLink to="/home" class="btn btn-dark">Voltar para Home</RouterLink>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContatosSaf',
    setup() {
      const mandamentos = [
        "Honrarás a marca TXC em todas as tuas ações e comunicações.",
        "Seguirás fielmente as políticas comerciais e operacionais da rede.",
        "Atenderás teus clientes com excelência e sorriso no rosto, sempre.",
        "Zelarás pela padronização da loja e pela boa imagem da franquia.",
        "Cumprirás as metas e te esforçarás para superá-las.",
        "Participarás dos treinamentos e reuniões com disciplina e comprometimento.",
        "Utilizarás os sistemas e ferramentas TXC de forma adequada e regular.",
        "Colaborarás com a rede, compartilhando boas práticas e ideias inovadoras.",
        "Manterás teu estoque organizado e atualizado, evitando rupturas.",
        "Respeitarás o cliente, os colegas franqueados e a equipe de suporte da franqueadora."
      ];
  
      return { mandamentos };
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
  }
  
  .card {
    border-radius: 12px;
  }
  
  .list-group-item {
    font-size: 1.1rem;
    padding: 15px;
  }
  
  .btn {
    padding: 10px 30px;
    font-weight: bold;
  }
  </style>
  
<template>
    <div
      class="modal fade"
      id="termoModal"
      tabindex="-1"
      aria-labelledby="termoModalLabel"
      aria-hidden="true"
      ref="modalRef"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="termoModalLabel">Termo de Responsabilidade</h5>
            <button type="button" class="btn-close" @click="fecharModal"></button>
          </div>
          <div class="modal-body" ref="modalBody">
            <p>
              TERMO DE RESPONSABILIDADE DE EQUIPAMENTOS
            </p>
            <p>
              O presente termo normatiza o uso, manutenção e controle de aparelhos eletrônicos e de telefonia móvel, disponibilizados pela empresa TEXTILE XTRA CO. LTDA. inscrita no CNPJ sob o nº 42.548.082/0002-34. 
            </p>
            <p>
              A empresa entregou os seguintes equipamentos:
              <strong>Notebook lenovo – Patrimonio 217</strong>
            </p>
            <p>
              Para o colaborador João Batista, que atua como Analista no departamento Logística denominado de USUÁRIO, que assina esse termo estando ciente e de acordo com as seguintes condições:
            </p>
            <ol>
              <li>Responsável pela guarda e conservação do equipamento;</li>
              <li>Tem somente a DETENÇÃO e NÃO a PROPRIEDADE do equipamento. Com uso exclusivo para prestação de serviços profissionais, sendo terminantemente proibidos o empréstimo, aluguel, ou cessão deste a terceiros que não estejam a serviço da empresa</li>
              <li>A utilização do equipamento poderá ter caráter contínuo ou temporário e, ainda, deverá obrigatoriamente atender ao princípio da economicidade, observando-se o zelo pelo uso econômico, evitando utilizações desnecessárias</li>
              <li>Em casos de perda, furto, roubo ou extravio do equipamento, deverá informar imediatamente o Departamento do TI. Se o equipamento apresentar defeitos por falta de zelo ou ocasionado por parte do usuário, o valor do conserto ou reposição do bem poderá ser descontado em folha de pagamento, sendo imprescindível a disseminação sobre o cuidado e sobre uso consciente e econômico</li>
              <li>Na identificação de qualquer anormalidade ou defeito no equipamento, informar imediatamente o Departamento de TI. Evitando assim maior dano ao aparelho em decorrência de manutenção indevida</li>
              <li>Se compromete ao sigilo de informações confidenciais, que podem e devem ser conceituadas como segredo de indústria e negócio. Garante que não compartilhará com terceiros, nem mesmo com outros colaboradores da TXC</li>
              <li>Ao término da prestação de serviço ou do contrato individual de trabalho, compromete-se a devolver o equipamento em perfeito estado (considerando o desgaste natural pelo uso normal do equipamento) no mesmo dia em que for comunicado ou comunique o seu desligamento</li>
            </ol>
            <p>
              O encarregado / gestor do USUÁRIO está ciente e se compromete em acompanhar o uso e cumprimento das normas estabelecidas acima.
            </p>
            <br />
            <p>Goiânia ____/____/____.</p>
            <p>
              ____________________________________   &emsp;  ___________________________________<br />
              Usuário autorizado &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;    Encarregado / Gestor Departamento
            </p>
            <br />
            <p>
              <strong>DEVOLUÇÃO</strong><br />
              Atestamos que o bem foi devolvido em ____ /_________ /_______, nas seguintes condições:
            </p>
            <p>
              (_) Em perfeito estado &emsp; (_) Apresentando defeito &emsp; (_) Faltando peças/ acessórios.
            </p>
            <p>
              _______________________________________________________________<br />
              (Nome e Assinatura Responsável pelo Recebimento)
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="fecharModal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="imprimirTermo">
              Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Modal } from 'bootstrap';

  export default {

    name: 'ModalTermo',
    data(){
        return{
            modalInstance: null
        }
    },
    methods: {
        imprimirTermo(){
            const conteudo = this.$refs.modalBody.innerHTML;

            const printWindow = window.open('','', 'height=600, width=800');
            printWindow.document.write(
                `
                <html>
          <head>
            <title>Imprimir Termo</title>
            <link rel="stylesheet" href="bootstrap/dist/css/bootstrap.min.css">
            <style>
              /* Adicione estilos específicos, se necessário */
              body { padding: 20px; }
            </style>
          </head>
          <body>
                ${conteudo}
                </body>
                 </html>`
            )
        },
      abrirModal() {
        const modalElement = this.$refs.modalRef;


        this.modalInstance = new Modal(modalElement, { backdrop: 'static' });
        this.modalInstance.show();
      },
      fecharModal() {
        if(this.modalInstance){
            this.modalInstance.hide();
        }else{
            const modalElement = this.$refs.modalRef;
        const instance = Modal.getInstance(modalElement);
        if (instance) {
          instance.hide();
        }
        }
      }
    }
  };
  </script>
  
  
  

  
<template>
    <div class="container mt-5">
      <h1 class="text-center mb-4">{{ tituloDaPagina }}</h1>
      <form @submit.prevent="salvarLoja" class="card p-4 shadow">
        <div class="mb-3">
          <label for="nome" class="form-label">Nome da Loja</label>
          <input type="text" id="nome" v-model="loja.nome" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="nome_dono" class="form-label">Nome do Dono</label>
          <input type="text" id="nome_dono" v-model="loja.nome_dono" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="cnpj" class="form-label">CNPJ</label>
          <input type="text" id="cnpj" v-model="loja.cnpj" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="dashboard" class="form-label">Dashboard URL</label>
          <input type="text" id="dashboard" v-model="loja.dashboard" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="cep" class="form-label">CEP</label>
          <input type="text" id="cep" v-model="loja.cep" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="estado" class="form-label">Estado</label>
          <input type="text" id="estado" v-model="loja.estado" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="pais" class="form-label">País</label>
          <input type="text" id="pais" v-model="loja.pais" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="loja_path" class="form-label">Path da Loja</label>
          <input type="text" id="loja_path" v-model="loja.loja_path" class="form-control" required />
        </div>
        <button type="submit" class="btn btn-primary w-100">
          {{ isEdicao ? "Atualizar Loja" : "Cadastrar Loja" }}
        </button>
      </form>
      <p v-if="mensagem" class="mt-3 alert" :class="mensagem.includes('sucesso') ? 'alert-success' : 'alert-danger'">
        {{ mensagem }}
      </p>
    </div>
  </template>
  

  <script>
  import { ref, getCurrentInstance, onMounted, computed, watch } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import api from '@/globais/axios'
  import axios from 'axios'
  
  export default {
    name: "CadastroLoja",
    setup() {
      const loja = ref({
        nome: "",
        nome_dono: "",
        cnpj: "",
        dashboard: "",
        cep: "",
        estado: "",
        pais: "Brasil",
        loja_path: ""
      });
  
      const mensagem = ref("");
      const router = useRouter();
      const route = useRoute();
      const idParam = route.params.id;
  
      const isEdicao = computed(() => !!idParam);
      const tituloDaPagina = computed(() => isEdicao.value ? "Editar Loja" : "Cadastrar Loja");
  
      // Watch para CEP
      watch(() => loja.value.cep, async (novoCep) => {
        if (novoCep && novoCep.length === 8) {
          try {
            const response = await axios.get(`https://viacep.com.br/ws/${novoCep}/json/`);
            if (!response.data.erro) {
              loja.value.estado = response.data.uf;
              loja.value.pais = "Brasil"; // ou você pode trazer do backend se for internacionalizado
            } else {
              mensagem.value = "CEP não encontrado";
            }
          } catch (error) {
            mensagem.value = "Erro ao consultar o CEP";
            console.error(error);
          }
        }
      });
  
      onMounted(() => {
        if (isEdicao.value) {
          carregarLoja(idParam);
        }
      });
  
      const carregarLoja = async (id) => {
        try {
          const response = await api.get(`/lojas/${id}`);
          loja.value = response.data;
        } catch (error) {
          mensagem.value = "Erro ao carregar Loja";
          console.error(error);
        }
      };
  
      const salvarLoja = async () => {
        try {
          if (isEdicao.value) {
            await api.put(`/lojas/${idParam}`, loja.value);
            mensagem.value = "Loja atualizada com sucesso";
          } else {
            await api.post(`/lojas`, loja.value);
            mensagem.value = "Loja criada com sucesso";
          }
          setTimeout(() => router.push("/Lojas"), 1500);
        } catch (error) {
          mensagem.value = isEdicao.value ? "Erro ao atualizar a loja" : "Erro ao criar a loja";
          console.error("Erro:", error);
        }
      };
  
      return {
        loja,
        mensagem,
        isEdicao,
        tituloDaPagina,
        salvarLoja
      };
    }
  };
  </script>
  
  
<template>
  <div class="container mt-5">
    <h1 class="text-center mb-4">Impressão de Termos</h1>
    
    <!-- Campo de busca por departamento -->
    <div class="mb-4">
      <input
        type="text"
        class="form-control"
        placeholder="Buscar departamento..."
        v-model="searchQuery"
      />
    </div>

    <!-- Exibimos apenas o(s) departamento(s) da página atual -->
    <div
      v-for="dep in paginatedDepartments"
      :key="dep"
      class="card mb-4 d-flex"
    >
      <div class="card-header bg-primary text-white">
        {{ dep }}
      </div>
      <div class="card-body">
        <!-- Dentro de cada departamento, temos um objeto com nomes, então iteramos em cada nome -->
        <div
          class="card mb-3"
          v-for="(itens, nome) in patrimonios[dep]"
          :key="nome"
        >
          <div class="card-header">
            {{ nome }}
          </div>
          <div class="card-body p-2">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="(item, index) in itens"
                :key="index"
              >
                <strong>{{ item.npatrimonioPc }}</strong> - {{ item.descricao }}
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-sm btn-secondary me-2"
              @click="abrirTermoModal(nome)"
            >
              <i class="bi bi-printer me-1"></i>
              Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Paginação -->
    <div class="d-flex justify-content-center" v-if="totalPages > 1">
      <nav>
        <ul class="pagination">
          <li
            class="page-item"
            :class="{ disabled: currentPage === 1 }"
          >
            <button
              class="page-link"
              @click="changePage(currentPage - 1)"
              :disabled="currentPage === 1"
            >
              Anterior
            </button>
          </li>

          <li
            v-for="page in totalPages"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <button
              class="page-link"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
          </li>

          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <button
              class="page-link"
              @click="changePage(currentPage + 1)"
              :disabled="currentPage === totalPages"
            >
              Próximo
            </button>
          </li>
        </ul>
      </nav>
    </div>

    <!-- Mensagem de erro ou sucesso -->
    <p
      v-if="mensagem"
      class="mt-3 alert"
      :class="mensagem.includes('sucesso') ? 'alert-success' : 'alert-danger'"
    >
      {{ mensagem }}
    </p>
  </div>

  <!-- ModalTermo -->
  <ModalTermo ref="ModalTermo"/>
</template>

<script>
import axios from "axios";
import api from "@/globais/axios";
import ModalTermo from "./ModalTermo.vue";

export default {
  name: "ListaPatrimonios",
  components: {
    ModalTermo,
  },
  data() {
    return {
      // Objeto contendo: { "Departamento A": { ... }, "Departamento B": { ... } }
      patrimonios: {},
      mensagem: "",
      
      // Paginação
      currentPage: 1,
      itemsPerPage: 1, // 1 departamento por página
      
      // Campo de busca
      searchQuery: "",
    };
  },
  computed: {
    /**
     * Retorna um array com todos os nomes de departamentos
     * ['Departamento A', 'Departamento B', ...]
     */
    allDepartments() {
      return Object.keys(this.patrimonios);
    },

    /**
     * Filtra os departamentos pelo nome, de acordo com 'searchQuery'
     */
    filteredDepartments() {
      if (!this.searchQuery) {
        return this.allDepartments;
      }
      const query = this.searchQuery.toLowerCase();
      return this.allDepartments.filter((dep) =>
        dep.toLowerCase().includes(query)
      );
    },

    /**
     * Quantidade total de páginas, considerando apenas os departamentos filtrados
     */
    totalPages() {
      return Math.ceil(this.filteredDepartments.length / this.itemsPerPage);
    },

    /**
     * Retorna o(s) departamento(s) da página atual
     */
    paginatedDepartments() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredDepartments.slice(startIndex, endIndex);
    },
  },
  created() {
    // Requisição para obter os patrimônios do backend
    api
      .get(`/patrimonio/imprimir`)
      .then((response) => {
        this.patrimonios = response.data;
      })
      .catch((error) => {
        console.error("Erro ao carregar patrimônios:", error);
        this.mensagem = "Erro ao carregar patrimônios.";
      });
  },
  methods: {
    /**
     * Altera a página, caso seja válida
     */
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },

    abrirTermoModal(nome) {
      // Lógica que abre o modal ou chama a impressão
      // Ajuste conforme sua implementação no ModalTermo
      this.$refs.ModalTermo.abrirModal(nome);
    },

    excluirPatrimonio(id) {
      api
        .delete(`/patrimonio/${id}`)
        .then(() => {
          this.mensagem = "Patrimônio excluído com sucesso!";
          // Exemplo de remoção do objeto 'patrimonios'
          // Obs.: Ajuste conforme sua lógica interna
          // Para cada departamento, para cada nome, filtrar o item excluído, etc.
        })
        .catch((error) => {
          console.error("Erro ao excluir patrimônio:", error);
          this.mensagem = "Erro ao excluir patrimônio.";
        });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
  max-height: 100%;
}

.cards {
  display: flex;
  flex-direction: column;
}

.card {
  border-radius: 10px;
}
</style>

<template>
  <div class="container mt-5">
    <h1 class="text-center mb-4">Lojas por Estado</h1>
    <div v-if="Object.keys(lojasPorEstado).length">
      <div v-for="(lojas, estado) in lojasPorEstado" :key="estado" class="mb-4">
        <h3 class="bg-light p-2">{{ estado }}</h3>
        <ul class="list-group">
          <li v-for="loja in lojas" :key="loja.id" class="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <strong>{{ loja.nome }}</strong> <br/>
              Dono: {{ loja.nome_dono }} <br/>
              CNPJ: {{ loja.cnpj }}
            </div>
            <router-link :to="`/loja/${loja.id}`" class="btn btn-primary btn-sm">Ver Detalhes</router-link>
          </li>
        </ul>
      </div>
    </div>
    <p v-else>Nenhuma loja encontrada.</p>
  </div>
</template>

<script>
import api from '@/globais/axios';

export default {
  name: 'ListaLojas',
  data() {
    return {
      lojasPorEstado: {},
    };
  },
  async mounted() {
    try {
      const response = await api.get('/lojas');
      const lojas = response.data;

      // Agrupando por estado
      this.lojasPorEstado = lojas.reduce((acc, loja) => {
        if (!acc[loja.estado]) {
          acc[loja.estado] = [];
        }
        acc[loja.estado].push(loja);
        return acc;
      }, {});

    } catch (error) {
      console.error('Erro ao buscar lojas:', error);
    }
  },
};
</script>

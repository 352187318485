<template>
  <div v-if="isAutenticado && carregouBi" class="all">
    <div class="container mt-5">
      <div class="card shadow-lg">
        <div class="card-body">
          <h1 class="card-title text-center mb-4">
            Portal do Franqueado
            <img
              src="https://txc.com.br/cdn/shop/files/txcccc.png?v=1723577085&amp;width=160"
              alt=""
            />
          </h1>
          <hr />
          <div class="row row-cols-3 g-3 my-4">
            <div class="col" v-for="(link, index) in links" :key="index">
              <div v-if="link.router" class="btn btn-primary bg-dark text-white w-100 botoes">
                <RouterLink :to="link.url" style="text-decoration: none; color: white;">
                  {{ link.name }}
                </RouterLink>
              </div>
              <div v-else>
                <a :href="link.url" target="_blank" class="btn btn-primary bg-dark text-white w-100 botoes">
                  {{ link.name }}
                </a>
              </div>
            </div>
          </div>

          <hr />
          <div class="alert alert-success text-center">
            <!-- Quando for maior que 800px, mostra o iframe -->
            <iframe
              v-if="!isMobile"
              title="Faturamento Geral TXC NEWS"
              width="100%"
              height="373.5"
              :src="urlbi"
              frameborder="0"
              allowFullScreen="true"
            ></iframe>

            <!-- Quando for <=800px, mostra o botão -->
            <a v-else :href="urlbi" target="_blank" class="btn btn-success mt-3">
              Acessar PowerBI
            </a>
          </div>

          <hr />

          <!-- Centralizando o botão de logout -->
          <div class="d-flex justify-content-center my-4">
            <a @click="logout" class="btn bg-dark text-white px-3 py-2" style="width: 150px; font-size: 0.9rem;">
              LOGOUT
            </a>
          </div>
        </div>

        <div v-if="userEmail" class="alert alert-success">
          Bem-vindo, <strong>{{ userEmail }}</strong>! Você está autenticado.
        </div>
      </div>
    </div>
    <MensagemGlobal />
  </div>
  <div v-else>
    Carregando...
  </div>
</template>


<script>
import api from "@/globais/axios";
import { useAuthStore } from "@/stores/authStore";
import { ref, onMounted, onBeforeUnmount } from "vue";
import router from "@/router";
import MensagemGlobal from "../Mensagens/MensagemGlobal.vue";
import { useMensagemStore } from "@/stores/mensagemStore";

export default {
  setup() {
    const authStore = useAuthStore();
    const userEmail = ref(localStorage.getItem("userEmail"));
    const urlbi = ref({});
    const isMobile = ref(false);
    const isAutenticado = ref(false);
    const carregouBi = ref(false);  // Variável de controle para o carregamento do BI
    const mensagem = useMensagemStore();

    const links = ref([
      { name: "NewsLetter", url: "https://ti.txc.com.br/txcnews", router: false },
      { name: "Manual do Franqueado", url: "https://youtube.com", router: false },
      { name: "Politica Comercial", url: "https://stackoverflow.com", router: false },
      { name: "TXC Plus", url: "https://txc.plus", router: false },
      { name: "Treinamento de Sistemas", url: "https://txc.plus", router: false },
      { name: "Duvidas Frequentes", url: "/duvidasfrequentes", router: true },
      { name: "Combate a falsificação", url: "https://crm.txc.com.br", router: false },
      { name: "Financeiro", url: "https://portal.exciasistemas.com.br/", router: false },
      { name: "Inovações", url: "https://ti.txc.com.br/txcnews", router: false },
      { name: "10 Mandamentos do Franqueado", url: "/mandamentos", router: true },
      { name: "TXC Camp", url: "https://web.whatsapp.com", router: false },
      { name: "Contatos SAF", url: "/contatosaf", router: true },
    ]);

    const carregarBi = async () => {
      try {
        // Supondo que a permissão exista e possua a propriedade lojas[0].id
        const idBi = authStore.permissoes.lojas[0].id;
        const response = await api.get(`/lojas/bi/${idBi}`);
        urlbi.value = response.data;
      } catch (error) {
        mensagem.mostrar('Erro ao carregar relatório PowerBi', 'erro');
      } finally {
        carregouBi.value = true; // Indica que o carregamento do BI foi concluído
      }
    };

    const logout = () => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userEmail");
      authStore.logout();
      router.push("/login");
    };

    const checkAutenticado = () => {
      const token = authStore.token;
      isAutenticado.value = !!token;
    };

    const checkScreenSize = () => {
      isMobile.value = window.innerWidth <= 700;
    };

    onMounted(() => {
      checkAutenticado();
      carregarBi();
      checkScreenSize();
      window.addEventListener("resize", checkScreenSize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkScreenSize);
    });

    return {
      userEmail,
      links,
      logout,
      urlbi,
      authStore,
      isMobile,
      isAutenticado,
      carregouBi, // Retorne a variável para uso no template
    };
  },
};
</script>


<style scoped>
.container {
  max-width: 900px;
}
.card {
  border-radius: 12px;
}
.btn {
  padding: 10px;
  font-weight: bold;
}
.botoes {
  border: none;
}

/* Responsividade para o grid dos botões */
@media (max-width: 768px) {
  .row-cols-3 > .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .row-cols-3 > .col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Centralizar o botão de logout */
.d-flex {
  display: flex;
  justify-content: center;
}

.d-flex .btn {
  width: 50%;
}

</style>

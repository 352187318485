<template>
  <div class="container mt-5">
    <div class="card shadow-lg">
      <div class="card-body">
        <h1 class="card-title text-center mb-4">Dúvidas Frequentes</h1>
        <hr class="my-4">
        
        <div class="accordion" id="faqAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="AcessoSistema">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#AcessoSistemas"
                aria-expanded="true"
                aria-controls="AcessoSistemas"
              >
                Como faço para acessar o sistema Excia?
              </button>
            </h2>
            <div
              id="AcessoSistemas"
              class="accordion-collapse collapse show"
              aria-labelledby="AcessoSistema"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Você pode acessar o sistema clicando <a href="http://ti.txc.com.br/excia" target="_blank" rel="noopener noreferrer">ti.txc.com.br/excia</a>.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="manualdofranqueado">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#manualdofranqueados"
                aria-expanded="false"
                aria-controls="manualdofranqueados"
              >
                Onde encontro o Manual do Franqueado?
              </button>
            </h2>
            <div
              id="manualdofranqueados"
              class="accordion-collapse collapse"
              aria-labelledby="manualdofranqueado"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                O Manual do Franqueado está disponível no portal. Ao clicar em "Manual do Franqueado", o download do PDF será iniciado.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="portalfinanceiro">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#portalfinanceiros"
                aria-expanded="false"
                aria-controls="portalfinanceiros"
              >
                Como posso acessar os relatórios financeiros?
              </button>
            </h2>
            <div
              id="portalfinanceiros"
              class="accordion-collapse collapse"
              aria-labelledby="portalfinanceiro"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Os relatórios financeiros, XML das notas e boletos estão disponíveis no portal do sistema Excia, em <a href="http://portal.exciasistemas.com.br" target="_blank" rel="noopener noreferrer">portal.exciasistemas.com.br</a>.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="vendaexcia">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#vendaexcias"
                aria-expanded="false"
                aria-controls="vendaexcias"
              >
                Como realizar uma venda no Excia?
              </button>
            </h2>
            <div
              id="vendaexcias"
              class="accordion-collapse collapse"
              aria-labelledby="vendaexcia"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Para realizar uma venda no Excia, é necessário ter acesso ao <a href="http://ti.txc.com.br/excia" target="_blank" rel="noopener noreferrer">sistema Excia</a> e acessar o <a href="http://txc.plus" target="_blank" rel="noopener noreferrer">txc.plus</a> para acompanhar os treinamentos disponibilizados.
              </div>
            </div>
          </div>
          
          <div class="accordion-item">
            <h2 class="accordion-header" id="devolucaoexcia">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#devolucaoexcia"
                aria-expanded="false"
                aria-controls="devolucaoexcia"
              >
                Como realizar uma devolução no Excia?
              </button>
            </h2>
            <div
              id="devolucaoexcia"
              class="accordion-collapse collapse"
              aria-labelledby="devolucaoexcia"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Para realizar uma devolução no Excia, é necessário ter acesso ao <a href="http://ti.txc.com.br/excia" target="_blank" rel="noopener noreferrer">sistema Excia</a> e acessar o <a href="http://txc.plus" target="_blank" rel="noopener noreferrer">txc.plus</a> para acompanhar os treinamentos disponibilizados.
              </div>
            </div>
          </div>
          
          <div class="accordion-item">
            <h2 class="accordion-header" id="estoqueexcia">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#estoqueexcia"
                aria-expanded="false"
                aria-controls="estoqueexcia"
              >
                Como retirar um relatório de estoque no Excia?
              </button>
            </h2>
            <div
              id="estoqueexcia"
              class="accordion-collapse collapse"
              aria-labelledby="estoqueexcia"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Para consultar seu estoque no Excia, acesse o <a href="http://ti.txc.com.br/excia" target="_blank" rel="noopener noreferrer">sistema Excia</a> e, no seu portal, na tela 2020, selecione seu depósito nas duas opções de depósito e clique em "Consultar". Após imprimir o relatório, você encontrará informações de valor de custo de estoque e quantidade de peças disponíveis.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Como faço para entrar em contato com o suporte?
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Para entrar em contato com o suporte, envie um e-mail para saf@txc.com.br ou acesse a seção "Suporte" no portal para abrir um chamado.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-3 mb-2">
        <RouterLink to="/home" class="btn btn-dark">Voltar para Home</RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'duvidasfrequentes',
};
</script>

<style scoped>
.container {
  max-width: 900px;
}
.card {
  border-radius: 12px;
}
.accordion-button {
  font-weight: bold;
}
.accordion-body {
  padding: 15px;
}
</style>
